import axios from "axios";
import { blendModuleAPI } from "../../../config";
export default {
  async getBlendCategory() {
    return await axios
      .get(`${blendModuleAPI}/v1/BlendCategory`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async createBlendCategory(data) {
    return await axios
      .post(`${blendModuleAPI}/v1/BlendCategory`, {
        name: data.name,
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async editBlendCategory(id, data) {
    return await axios
      .put(`${blendModuleAPI}/v1/BlendCategory/` + id, {
        name: data.name,
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async deleteBlendCategory(id) {
    return await axios
      .delete(`${blendModuleAPI}/v1/BlendCategory/` + id)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
